import { useLayoutEffect } from "react"

const useConditionalScrollLock = (shouldLock: boolean) => {
  useLayoutEffect(() => {
    if (!shouldLock) return

    const originalStyle = window.getComputedStyle(document.body).overflow
    document.body.style.overflow = "hidden"

    return () => {
      document.body.style.overflow = originalStyle
    }
  }, [shouldLock])
}

export default useConditionalScrollLock
